import { Pane } from 'evergreen-ui'
import React from 'react'

const ConsoleCustomers = () => {

  
  return (
    <Pane>Customers </Pane>
  )
}

export default ConsoleCustomers