import { TextField } from '@mui/material'

const Input = ({value, onChange, label, ref = null, autoFocus = false, theme = 'light', type = 'text', className="", height = 72, error = false}: any) => {
  console.log(theme)
  return (
      <TextField
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  size='medium'
                  label={label}
                  type={type}
                  error={error}
                  variant='filled'
                  sx={{
                    "& .MuiFilledInput-root": {
                      height: height,
                    }
                  }}
                  inputRef={(input) => autoFocus && input?.focus()}
                  InputProps={{
                    ref: ref,
                    disableUnderline: true,
                    className: 'w-full text-3xl font-bpg',
                    style: {
                      color: theme === 'black' ? 'black' : 'white',
                      fontFamily: 'bpg'
                    }
                  }}
                  className={`border ${theme === 'black' ? '!border-black' : 'border-white'} rounded-xl p-5 w-full [&>label]:!font-fira ${className}`}
              />
  )
}

export default Input