import { Dispatch } from "@reduxjs/toolkit"
import { requestWS } from "../../services/axios.config"
import { LocalStorage } from "../../utils/helpers/localStorage"


export const authAction = ({username, password}: any) => (dispatch: Dispatch) => {
	
	dispatch({
		type: "auth/loading",
		payload: true
	})

 requestWS
		.post('/auth/login', {username, password})
		.then((response) => {
			dispatch({
				type: "auth/login",
				payload: response.data
			})
			LocalStorage.set('token', response.data.token)
		}).catch((error) => {
			dispatch({
				type: "auth/error",
				payload: error
			})
		})
	
}

export const checkAuthToken = () => (dispatch: Dispatch) => {

	const token = LocalStorage.get('token')

	dispatch({
		type: "auth/loading",
		payload: true
	})

	if (token) {
		requestWS
			.get('/auth/authorized', {
				headers: {
					Authorization: token
				}
			})
			.then((response) => {
				dispatch({
					type: "auth/authorized",
					payload: response.data
				})
			}).catch((error) => {
				if(error.response){
					if(error.response?.status === 401){
						LocalStorage.remove('token')
						dispatch({
							type: "auth/authorized",
							payload: {token: '', message: 'Unauthorized', error: true}
						})
					}
				}else{
					dispatch({
						type: "auth/error",
						payload: error
					})
				}
				
			})
	}


}

export const logout = () => (dispatch: Dispatch) => {
	LocalStorage.remove('token')
	dispatch({
		type: "auth/logout",
		payload: true
	})
}