// write subscribe reducer

import { createSlice } from "@reduxjs/toolkit";

const bookingSlice = createSlice({
    name: "booking",
    initialState: {
			data: [],
			loading: false,
			error: false,
			message: '',
    },
    reducers: {
			setRecords: (state, action) => {
				state.data = action.payload
				state.loading = false
				state.error = false
			},
			loading: (state, action) => {
				state.loading = action.payload
				state.error = false
				state.message = 'Fetching data...'
			},
			error: (state, action) => {
				state.data = []
				state.loading = false
				state.error = true
				state.message = "Something went wrong"
			}
			
    },
});

export const { setRecords, loading, error } = bookingSlice.actions;
export default bookingSlice.reducer;