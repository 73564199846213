import {
  CircularProgress,
  Paper,
  TableFooter,
  TablePagination,
  TextField
} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from 'react';
import { TableErrorMessage } from '../errorMessage/ErrorMessage.obs';

type Row = {
  name: string | any
  className?: string
  onClick?: () => void
  options?: {
    wrapText?: boolean
    tableWidth?: number
  }
}

type RowAction = { // eslint-disable-line
  name: string
  className: string
  onClick: () => void
  icon: React.ReactNode
}

interface TableInterface {
  search?: {
    show: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    value?: string
  }
  simple?: boolean
  poor?: boolean
  headerRows: Row[]
  BodyRows: () => JSX.Element
  error?: boolean
  loading?: boolean
  pagination?: {
    show: boolean;
    total?: number;
    page?: number;
    rowsPerPage?: number;
    handleChangePage?: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  },
  option?: {
    action?: boolean
    collapse?: boolean
    collapse2?: boolean
  } | undefined,
  HeaderComponent?: () => JSX.Element
}

const ObsTable = ({ simple = false, poor = false, HeaderComponent, headerRows, BodyRows, error = false, loading = false, pagination = { show: false }, search = { show: false }, option = { action: false, collapse: false } }: TableInterface) => {

  const innerTheme = createTheme({
    palette: {
      mode: 'light'
    },
  });


  return (
    <ThemeProvider theme={innerTheme}>
      <TableContainer className={`rounded-lg ${!simple && 'border'} ${poor && 'table-container-poor'} w-full `} component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
          className={` ${poor ? 'table-poor' : 'table-container'}`}
        >
          {HeaderComponent && <TableHead className="head">
            <TableRow className="flex items-end justify-end">
              <TableCell className='w-min min-w-min' colSpan={headerRows.length + (option?.collapse ? 1 : 0) + (option?.action ? 1 : 0)}> <HeaderComponent /> </TableCell>
            </TableRow>
          </TableHead>}
          <TableHead className="head">
            {search.show && <TableRow className="flex items-end justify-end">
              <TableCell className=" !p-2 !border-none" colSpan={3}>
                <TextField className="w-full [&>*]:!text-xs" placeholder="ძებნა" variant="outlined" size='small' value={search.value} onChange={search.onChange} />
              </TableCell>
            </TableRow>}
            <TableRow>
              {(option?.collapse) && <TableCell />}
              {headerRows.map((row: Row) => (
                <TableCell key={row.name} className={`${row?.options?.wrapText ? 'whitespace-nowrap' : ''} ${row.className || ''}`}>
                  {typeof row.name === "string" ? row.name : row.name}
                </TableCell>
              ))}
              {option?.action && <TableCell>Action</TableCell>}
              {(option?.collapse2) && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody className=' bg-neutral-300'>
            {!error ? (
              loading ? (
                <TableRow className="h-32">
                  <TableCell className="!text-center bg-neutral-300 " colSpan={11}>
                    <CircularProgress
                      color="secondary"
                      variant="indeterminate"
                    />
                  </TableCell>
                </TableRow>
              ) : (
                <BodyRows />
              )
            ) : (
              <TableRow className="h-32">
                <TableCell className="!text-center bg-neutral-300 " colSpan={11}>
                  <TableErrorMessage />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter className="footer">
            {/* <Table.SearchHeaderCell className='[&>*]:text-neutral-500 ' /> */}
          </TableFooter>
        </Table>
        {pagination.show && <TablePagination
          className='bg-neutral-100'
          component="div"
          count={pagination?.total || 0}
          page={pagination?.page || 0}
          onPageChange={pagination?.handleChangePage || (() => { })}
          labelRowsPerPage="რაოდენობა: "
          rowsPerPageOptions={[5, 10, 20, 50, 100, 250, 500, 1000]}
          rowsPerPage={pagination?.rowsPerPage || 20}
          onRowsPerPageChange={pagination?.handleChangeRowsPerPage || (() => { })}
        />}
      </TableContainer>
    </ThemeProvider>
  )
}

export default ObsTable