import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, TextField } from '@mui/material';

const AutoComplete = ({data, value, onChange, loading, label, error}: any) => {
  return (
    <Autocomplete
          disablePortal
          value={value}
          onChange={(e, value:any) => onChange(value)}
          getOptionLabel={(option: any) => option.name}
          getOptionKey={(option: any) => option.id}
          loading={loading}
          
          isOptionEqualToValue={(option: any, value:any) => option.id === value.id}
          options={
            data?.map((item: any) => ({
              id: item.id,
              name: `${item.name}`,
            })) || []
          }
          popupIcon={<KeyboardArrowDownIcon sx={{ color: 'gray' }} />}
          renderInput={(params) => (
            <TextField
              variant="filled"
              {...params}
              size="medium"
              error={error}
              label={label}
                sx={{
                    "& .MuiFilledInput-root": {
                        height: 72,
                        fontFamily: 'bpg',
                        fontSize: '16px',
                        color: 'white'
                    }
                }}
            />
          )}
          className='w-full '
        />
  )
}

export default AutoComplete