import { Pane, Spinner } from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as Logo } from '../../assets/icons/Logo.svg';
import { logout } from '../../Redux-store/selectors/auth.action';

const Header = () => {

  const authSelector = useSelector((state: any) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate();

 const handleAuth = () => {
   if (authSelector.isLoggedIn) {
     logout()(dispatch)
     navigate('/auth')
  }else{
    navigate('/auth')
  }
}

  return (
    <Pane className="mt-8 header !items-start">
        <Logo className='w-28' />
        {authSelector.loading ? <Pane><Spinner /></Pane> :
  authSelector.isLoggedIn ? <Pane className="flex gap-5">
    <Pane className="font-bpg text-base text-center rounded-lg px-4 py-3 bg-[#111] text-white absolute right-0 cursor-pointer max-md:hidden" onClick={() => navigate('/console')}>Console</Pane>
    </Pane> :
        !authSelector.isLoggedIn ? <Pane className="font-bpg text-base text-center rounded-lg px-4 py-3 bg-[#111] text-white absolute right-0 cursor-pointer max-md:hidden" onClick={handleAuth} >
          ავტორიზაცია
        </Pane> : null}
        
    </Pane>
  )
}

export default Header