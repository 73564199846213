import { Pane, Spinner } from "evergreen-ui";
import { ReactComponent as Logo } from '../../assets/icons/Logo.svg';

import { useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Input from "../../components/ui/input";
import { authAction } from "../../Redux-store/selectors/auth.action";
import "./index.css";


const Auth = () => {

	const dispatch = useDispatch()
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const authSelector = useSelector((state: any) => state.auth)
	const navigate = useNavigate();
  const location = useLocation();
	const isLoggedIn = authSelector.isLoggedIn

	const handleAuth = () => {

		if (!username || !password) {
			alert('შეიყვანეთ ყველა ველი')
			return
		}

		authAction({username, password})(dispatch)
	}

	useEffect(() => {
    if (isLoggedIn) {
      navigate("/console");
    }

    if (location.pathname === "/auth") {
      document.title = "Avtosave / Authorization";
    }
  });

	return (
		<Pane className="auth">
			<Pane>
				<MdOutlineKeyboardBackspace  className="cursor-pointer text-3xl absolute m-5 top-0 left-0" color="white" onClick={() => window.location.href = '/'} />
			</Pane>
			<Pane>
				<Logo className='w-28 invert' />
			</Pane>
			<Pane className="flex flex-col gap-2 w-80">
				<Input value={username} onChange={setUsername} label='Username' />
				<Input value={password} onChange={setPassword} label='Password' type='password' />
				<Pane onClick={() => handleAuth()} className="select-none cursor-pointer font-bpg bg-white rounded-xl text-[#111] flex w-full h-[72px] text-xl font-bold justify-center items-center mt-5">{authSelector.loading ? <Spinner /> : 'ავტორიზაცია'}</Pane>
			</Pane>
			<Pane className="absolute m-5 bottom-0 right-0 select-none font-bpg">
				<Pane className="text-red-600">{authSelector.error && authSelector.message}</Pane>
				<Pane className="text-green-600">{!authSelector.error && authSelector.message}</Pane>
			</Pane>
		</Pane>
	)

}

export default Auth