import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles/index.css"
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReduxStore from './Redux-store/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const customTheme = createTheme({
  palette: {
    mode: 'dark'
  },
});
root.render(
    <ThemeProvider theme={customTheme}>
      <Provider store={ReduxStore}>
          <BrowserRouter>
                  <App />
          </BrowserRouter>
      </Provider>
    </ThemeProvider>
);

