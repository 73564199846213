import { Dispatch } from "@reduxjs/toolkit";
import { toaster } from "evergreen-ui";
import socket from "../../services/socket.config";
import {
  messageReceived,
  socketConnect,
  socketDisconnect,
} from "../reducers/socket.reducer";

/**
 * Connects to the socket and dispatches actions based on socket events.
 *
 * @param {Function} dispatch - The dispatch function from Redux.
 * @return {void} This function does not return anything.
 */
export const connectToSocket = () => (dispatch: Dispatch) => {
  socket.on("connect", () => {
    console.log("connected");
    dispatch(socketConnect());
  });

  socket.on('connect-check', () => {
    dispatch(socketConnect());
  })

  socket.on("disconnect", () => {
    dispatch(socketDisconnect());
  });
};

/**
 * Reconnects to the socket and dispatches actions when connected or disconnected.
 *
 * @param {Function} dispatch - The Redux dispatch function.
 * @return {void} This function does not return anything.
 */
export const reconnectToSocket = () => (dispatch: Dispatch) => {
  socket.connect();

  socket.on("connect", () => {
    dispatch(socketConnect());
  });

  socket.on("disconnect", () => {
    dispatch(socketDisconnect());
  });
};

export const ListenSocketMessages = () => (dispatch: Dispatch) => {
  socket.on("message", (message: any) => {
    dispatch(messageReceived(message));
    toaster.success(message);
  });

  socket.on("server-time", (time: any) => {
    dispatch({
      type: "socket/serverTime",
      payload: { time },
    })
  });
};
