// write reduxjs/toolkit store

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth.reducer";
import bookingReducer from "./reducers/booking.reducer";
import dataReducer from "./reducers/data.reducer";
import socketReducer from "./reducers/socket.reducer";
import subscribeReducer from "./reducers/subscribe.reducer";

const store = configureStore({
    reducer: {
        subscribe: subscribeReducer,
        data: dataReducer,
        socket: socketReducer,
        auth: authReducer,
        booking: bookingReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
      })
})

export default store
