// write subscribe reducer

import { createSlice } from "@reduxjs/toolkit";

const subscribeSlice = createSlice({
    name: "subscribe",
    initialState: {
        form: {
            fullName: "",
            email: "",
            phone: "",
            carBrand: "",
            carModel: "",
            date: "",
            time: "",
        },
        status: {
            loading: false,
            error: false,
            success: false,
            message: "",
        }
    },
    reducers: {
        setForm(state, action) {
            state.form = action.payload
        },
        resetForm(state) {
            state.form = {
                fullName: "",
                email: "",
                phone: "",
                carBrand: "",
                carModel: "",
                date: "",
                time: "",
            }
            state.status.message = ""
            state.status.error = false
            state.status.success = false
            state.status.loading = false
        },
        setStatus(state, action) {
            switch(action.payload.type) {
                case "loading": 
                    state.status.loading = action.payload.value
                    state.status.success = false
                    state.status.error = false
                    state.status.message = ""
                break;
                case "error": 
                    state.status.error = action.payload.value
                    state.status.success = false
                    state.status.loading = false
                break;
                case "success": 
                    state.status.success = action.payload.value
                    state.status.error = false
                    state.status.loading = false
                    state.status.message = ""
                break;
                case "message": 
                    state.status.message = action.payload.value
                break;
                default: 
                    console.log("error");
            }

        }
    },
});

export const { setForm, setStatus } = subscribeSlice.actions;
export default subscribeSlice.reducer;