// write subscribe reducer

import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
			login: {
				username: '',
				password: '',
				token: ''
			},
			loading: false,
			message: '',
			error: false,
			isLoggedIn: false
    },
    reducers: {
			logout: (state) => {
				state.isLoggedIn = false
				state.login.token = ''
				state.login.username = ''
				state.login.password = ''
				state.loading = false
				state.message = ''
				state.error = false
			},
			authorized: (state, action) => {
				state.login.token = action.payload.token
				state.loading = false
				state.message = action.payload.message
				state.error = false
				state.isLoggedIn = true
			},
			login(state, action) {
				state.login = action.payload
				state.loading = false
				state.error = false
				state.message = 'Login successful'
				state.isLoggedIn = true
			},
			loading: (state, action) => {
				state.loading = true
				state.message = 'Connecting to server...'
				state.error = false
				state.isLoggedIn = false
			},
			error: (state, action) => {
				state.loading = false;
				state.login.username = ''
				state.login.password = ''
				state.message = action.payload?.response?.data?.message || 'Error connecting to server'
				state.error = true
				state.isLoggedIn = false
			}
    },
});

export const { login, loading, error  } = authSlice.actions;
export default authSlice.reducer;