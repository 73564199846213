// write selector for subscriber

import { Dispatch } from "@reduxjs/toolkit";
import { requestWS } from "../../services/axios.config";


export const getBrands = () => (dispatch: Dispatch) => {
	dispatch({
		type: "data/brands",
		payload:  {
			type: "loading",
			value: true
		}
	})

	requestWS
		.get('/avtosave/brands')
		.then(res => {
			dispatch({
				type: "data/brands",
				payload:  {
					type: "data",
					value: res.data.data
				}
			})
		})
		.catch(err => {
			console.log(err)
		})
}

export const getModels = ({ brandId }: { brandId: number }) => (dispatch: Dispatch) => {
	dispatch({
		type: "data/models",
		payload:  {
			type: "loading",
			value: true
		}
	})

	requestWS
		.get(`/avtosave/models/${brandId}`)
		.then(res => {
			dispatch({
				type: "data/models",
				payload:  {
					type: "data",
					value: res.data.data
				}
			})
		})
		.catch(err => {
			console.log(err)
		})

}

export const setSubscriber = (data: { fullname: string, email: string, phone: string, carBrand: number, carModel: number, date?: any, time?: any }) => (dispatch: Dispatch) => {
	
	dispatch({
		type: "subscribe/setStatus",
		payload:  {
			type: "loading",
			value: true
		}
	})

	dispatch({
		type: "subscribe/setForm",
		payload: data
	})


	requestWS.post("/avtosave/booking", data).then(res => {
		console.log(res)
		dispatch({
			type: "subscribe/setStatus",
			payload:  {
				type: "success",
				value: true
			}
		})
	}).catch(err => {
		dispatch({
			type: "subscribe/setStatus",
			payload:  {
				type: "error",
				value: true
			}
		})
	})

}

export const getBookedDates = () => (dispatch: Dispatch) => {
	requestWS
		.get('/avtosave/booked-dates')
		.then(res => {
			dispatch({
				type: "data/bookedDates",
				payload:  {
					type: "data",
					value: res.data.data
				}
			})
		})
		.catch(err => {
			console.log(err)
		})

}
