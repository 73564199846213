import axios from "axios";
import { LocalStorage } from "../utils/helpers/localStorage";

const token = LocalStorage.get("token");

const axiosInstanceWS = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 100000,
  headers: {
    common: {
      Accept: "application/json",
    },
    Authorization: token,
  },
});

axiosInstanceWS.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    console.error("Error:", error);

    return Promise.reject(error);
  }
);

export const requestWS = axiosInstanceWS;
