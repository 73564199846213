import { Button, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Pane } from "evergreen-ui";
import React from "react";
import DatePicker from "../datepicker/DatePicker.obs";

interface TableFilterInterface {
  search?: {
    show: boolean,
    filter?: string,
    setFilter?: React.Dispatch<React.SetStateAction<string>>
  },
	date?: {
		startDate: string;
		setStartDate: React.Dispatch<React.SetStateAction<string>>;
		endDate: string;
		setEndDate: React.Dispatch<React.SetStateAction<string>>;
	};
	additionalComponent?: React.ReactNode[];
	onSubmit?: () => void
}


const TableFilter = ({date, onSubmit, additionalComponent, search = {show: false }}: TableFilterInterface) => {

  
const innerTheme = createTheme({
  palette: {
    mode: 'light'
  },
});


  return (
    <ThemeProvider theme={innerTheme}>
    <Pane className="tableFilter" >
			{search.show && <Pane className="flex flex-col gap-3 w-60">
				<Pane className="!text-xs">ძებნა</Pane>
				<TextField value={search.filter} onChange={e => search.setFilter && search.setFilter(e.target.value)} className="[&>*]:!text-xs"  placeholder="ჩაწერეთ საძიებო სიტყვა" variant="outlined" size="small" />
			</Pane> }
      
      {date && date.startDate && <DatePicker
        date={date.startDate}
        label="საწყისი თარიღი"
        onChange={date.setStartDate}
      />}
      {date && date.endDate && <DatePicker
        date={date.endDate}
        label="საბოლოო თარიღი"
        onChange={date.setEndDate}
      />}
			{additionalComponent && additionalComponent.map((Item: React.ReactNode, index: number) => (
				<React.Fragment key={index}>{Item}</React.Fragment>
			))}
      {onSubmit && <Button
        className="flex self-end"
        onClick={onSubmit}
      >
        ძებნა
      </Button>}
    </Pane>
    </ThemeProvider>
  );
};

export default TableFilter;
