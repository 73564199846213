import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Pane } from "evergreen-ui";
import { useEffect, useRef, useState } from 'react';
import { CgSpinnerTwoAlt } from "react-icons/cg";
import { CiCircleCheck } from "react-icons/ci";
import { IoRefreshCircleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Car } from '../../assets/images/car.svg';
import { ReactComponent as Line } from '../../assets/images/line.svg';
import Header from '../../components/common/header';
import AutoComplete from '../../components/ui/autocomplete';
import Input from '../../components/ui/input';
import { getBookedDates, getBrands, getModels, setSubscriber } from "../../Redux-store/selectors/subscriber.selector";
import socket from "../../services/socket.config";
import './index.css';

dayjs.extend(utc);
dayjs.extend(timezone);

const Home = () => {
  const timeZoneFromServer = "Asia/Tbilisi";

  const targetRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [inputFocused, setInputFocused] = useState(false);
  const subscribeStatus = useSelector((state: any) => state.subscribe.status)
  const subscribeForm = useSelector((state: any) => state.subscribe.form)
  const brands = useSelector((state: any) => state.data.brands)
  const models = useSelector((state: any) => state.data.models)
  const bookedDates = useSelector((state: any) => state.data.bookedDates)
  const dispatch = useDispatch();
  const [disableDates, setDisableDates] = useState([])
  const [disableTimes, setDisableTimes] = useState([])


  useEffect(() => {
    getBrands()(dispatch)
  }, [])

  useEffect(() => {
    getBookedDates()(dispatch)
  }, [])

  useEffect(() => {
    socket.on('newBooking', (data) => {
      dispatch({
        type: "data/bookedDates",
        payload: {
          type: "data",
          value: data
        }
      })
    })
  }, [])


  const scrollToSection = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
      });
      setTimeout(() => {
        setInputFocused(true)
      }, 500);
    }
  };

  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [brand, setBrand] = useState<any>(null);
  const [model, setModel] = useState<any>(null);
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const [time, setTime] = useState(null);

  const [fullNameError, setFullNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [brandError, setBrandError] = useState(false);
  const [modelError, setModelError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const getTimesForDate = (selectedDate: any) => {
    return bookedDates
      .filter((booking: any) => dayjs(booking.date).isSame(selectedDate, 'day'))
      .map((booking: any) => booking.time);
  };

  const handleDateChange = (newDate: any) => {
    setDate(newDate ? newDate.tz(timeZoneFromServer).format('YYYY-MM-DD') : null);
  };

  const disabledDates = [dayjs('2022-03-01'), dayjs('2022-03-02')];
  const disabledTimes = bookedDates.map((date: any) => date.time);

  const shouldDisableDate = (date: any) => {
    const today = dayjs().startOf('day');
    if (date.isBefore(today)) return true;
    return disabledDates.some((disabledDate: any) => date.isSame(disabledDate, 'day'));
  };

  const shouldDisableTime = (time: any) => {

    // dayjs.extend(utc);
    // dayjs.extend(timezone);

    const tbilisiTimezone = 'Asia/Tbilisi';

    const inputTime = dayjs(time, 'HH:mm:ss').tz(tbilisiTimezone);

    // Define working hours
    const workingStart = dayjs().tz(tbilisiTimezone).hour(6).minute(0).second(0); // 07:00:00
    const workingEnd = dayjs().tz(tbilisiTimezone).hour(21).minute(0).second(0); // 21:00:00

    // Check if input time is within working hours
    if (inputTime.isBefore(workingStart) || inputTime.isAfter(workingEnd)) {
      return true;
    }

    // Get the selected date
    const selectedDate = dayjs(date).format('YYYY-MM-DD');

    // Filter booked dates to only those matching the selected date
    const bookedTimes = bookedDates
      .filter((booking: any) => dayjs(booking.date).format('YYYY-MM-DD') === selectedDate)
      .map((booking: any) => booking.time);

    // Define the ±1 hour range
    const startRange = inputTime.subtract(1, 'hour');
    const endRange = inputTime.add(1, 'hour');

    // Check if the input time falls within ±1 hour of any booked time
    return bookedTimes.some((bookedTime: string) => {
      const bookedMoment = dayjs(bookedTime, 'HH:mm:ss');
      return bookedMoment.isAfter(startRange) && bookedMoment.isBefore(endRange);
    });
  };


  const handleSubscribe = () => {

    if (!fullName) setFullNameError(true)
    if (!phoneNumber) setPhoneNumberError(true)
    if (!email) setEmailError(true)
    if (!brand) setBrandError(true)
    if (!model) setModelError(true)
    if (!date) setDateError(true)
    if (!time) setTimeError(true)

    if (!fullName || !phoneNumber || !email || !brand || !model || !date || !time) return;

    setSubscriber({
      fullname: fullName,
      email: email,
      phone: phoneNumber,
      carBrand: brand?.id,
      carModel: model?.id,
      date: date,
      time: time
    })(dispatch)

  };

  const ConvertDate = ({ date, time }: { date: string | Date; time: string }) => {
    const newDate = new Date(date);
    const day = newDate.getDate();

    const georgianMonths = [
      'იანვარი',
      'თებერვალი',
      'მარტი',
      'აპრილი',
      'მაისი',
      'ივნისი',
      'ივლისი',
      'აგვისტო',
      'სექტემბერი',
      'ოქტომბერი',
      'ნოემბერი',
      'დეკემბერი'
    ];

    const month = georgianMonths[newDate.getMonth()];
    const timeDate = new Date(time);
    const hours = String(timeDate.getHours()).padStart(2, '0');
    const minutes = String(timeDate.getMinutes()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    return (
      <Pane className="flex gap-2 font-fira text-xl">
        {day} {month}, {formattedTime} საათი
      </Pane>
    );
  };

  const resetForm = () => {
    setFullName("");
    setPhoneNumber("");
    setEmail("");
    setBrand(null);
    setModel(null);
    setDate(null);
    setTime(null);

    dispatch({
      type: "subscribe/resetForm",
      payload: {}
    })
  };

  const handleBrandChange = (data: any) => {
    setBrand(data);
    setModel(null);
    if (data) getModels({ brandId: data.id })(dispatch)
  };

  return (
    <Pane>
      <Pane className="md:h-screen  !h-950-max-auto">
        <Pane className="part1"></Pane>
        <Pane><Header /></Pane>
        <Pane className="welcomePage">
          <Pane className="flex flex-col gap-5 w-2/3 z-20 relative max-md:w-fit">
            <Pane className="font-bold text-5xl font-bpg max-md:text-4xl">ავტოსეივი</Pane>
            <Pane className='font-fira max-md:w-fit'>ავტოსეივი არის წამყვანი კომპანია, რომელიც სპეციალიზებულია მანქანის შუშების დაბურვაში. ჩვენი მიზანია, თქვენს ავტომობილს მივცეთ როგორც ესთეტიკური, ასევე ფუნქციონალური გაუმჯობესება. ჩვენ ვიყენებთ მხოლოდ უმაღლესი ხარისხის მასალებს და ვთავაზობთ მომსახურების სრულ გარანტიას, რათა თქვენი კომფორტი და უსაფრთხოება მუდამ დაცული იყოს. ჩვენი პროფესიონალი გუნდი უზრუნველყოფს თითოეული დეტალის მაღალ დონეზე შესრულებას, რაც გვეხმარება ვიყოთ თქვენი სანდო პარტნიორი ავტომობილის შუშების დაბურვაში.</Pane>
            <Pane className="w-min max-sm:w-full">
              <Pane className=" select-none cursor-pointer font-bpg bg-[#111] rounded-xl text-white flex w-[299px] max-md:w-[240px] h-[72px] text-xl max-md:text-base  font-bold justify-center items-center max-sm:w-full" onClick={scrollToSection}>დაჯავშნე ვიზიტი</Pane>
            </Pane>
            {/* <Pane className="welcomePageCover"></Pane> */}
          </Pane>
          <Pane className="md:hidden !z-10 relative sm:h-[440px] sm:mt-10">
            {/* <Pane className="b-line"></Pane> */}
            <Pane className="">
              <Pane className="absolute max-sm:right-28 z-10 max-sm:top-14 font-bold max-sm:text-2xl font-bpg sm:right-[280px] sm:top-[30px] sm:text-4xl">60%</Pane>
              <Line className="absolute right-20 max-sm:w-16 z-10 line-text sm:!w-24 sm:right-[230px] sm:mt-5" />
              <Car className="-bottom-52 sm:-bottom-40 absolute right-0 sm:w-[600px] max-sm:!w-[400px]" />
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      <Pane className="md:h-screen bg-[#111] pageForm !h-800-max-h-400px z-0">
        <Pane className="pageFormContent">
          <Pane className="text-5xl max-md:text-3xl font-bpg font-bold text-white text-center  px-16">
            დაჯავშნე ვიზიტი მარტივად
          </Pane>
          {subscribeStatus.loading ? <Pane className="flex justify-center items-center"><CgSpinnerTwoAlt className="animate-spin w-16 h-16" color="white" /></Pane> :
            subscribeStatus.error ? <Pane className="select-none animate-pulse cursor-pointer font-bpg bg-white rounded-xl text-[#111] flex w-full h-[72px] text-xl font-bold items-center justify-center mt-5 px-16 mb-5 max-sm:w-[90%] self-center justify-self-center" onClick={() => resetForm()}>დაფიქსირდა შეცდომა, ცადეთ ხელმეორედ <IoRefreshCircleSharp className="w-7 h-7 ml-3 mb-1" /></Pane> :
              subscribeStatus.success ? <Pane className="flex justify-center items-center text-white flex-col gap-7 self-start mt-11 px-16 pb-16">
                <Pane>
                  <Pane className="bg-[#66D45D]/10 w-40 h-40 rounded-full flex justify-center items-center">
                    <CiCircleCheck className="w-16 h-16" color="#66D45D" />
                  </Pane>
                </Pane>
                <Pane className="text-3xl font-bpg font-bold text-center">თქვენი ჯავშანი წარმატებით გაიგზავნა</Pane>
                <Pane><ConvertDate date={new Date(subscribeForm.date)} time={subscribeForm.time} /> </Pane>
                <Pane onClick={() => resetForm()} className="select-none cursor-pointer font-bpg bg-white rounded-xl text-[#111] flex w-full h-[72px] text-xl max-md:text-sm font-bold justify-center items-center mt-5">ახალი ჯავშნის გაფორმება</Pane>
              </Pane> :
                <Pane className="subscriptionForm" ref={targetRef}>
                  <Input error={fullNameError} value={fullName} onChange={setFullName} label='სახელი, გვარი' ref={inputRef} autoFocus={inputFocused} />
                  <Input error={phoneNumberError} value={phoneNumber} onChange={setPhoneNumber} label='ტელეფონის ნომერი' />
                  <Input error={emailError} value={email} onChange={setEmail} label='ელ. ფოსტა' />
                  <Pane className="flex gap-3 w-full max-md:flex-col">
                    <AutoComplete error={brandError} data={brands?.data} value={brand} onChange={handleBrandChange} loading={brands?.loading} label='მანქანის მარკა' />
                    <AutoComplete error={modelError} data={models?.data} value={model} onChange={setModel} loading={models?.loading} label='მანქანის მოდელი' />
                  </Pane>
                  <Pane className="flex gap-3 w-full max-md:flex-col">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label="აირჩიე თარიღი"
                        shouldDisableDate={shouldDisableDate}
                        onChange={handleDateChange}
                        slotProps={{
                          textField: {
                            error: dateError,
                            size: "medium", variant: "filled", sx: {
                              "& .MuiFilledInput-root": {
                                height: 72,
                                fontFamily: 'bpg',
                              }
                            }
                          },
                        }}
                        className='w-full'
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        label="აირჩიე დრო"
                        shouldDisableTime={shouldDisableTime}
                        onChange={(value: any) => setTime(value)}
                        slotProps={{
                          textField: {
                            error: timeError,
                            size: "medium", variant: "filled", sx: {
                              "& .MuiFilledInput-root": {
                                height: 72,
                                fontFamily: 'bpg',
                              }
                            }
                          },
                        }}
                        ampm={false}
                        className='w-full'
                      />
                    </LocalizationProvider>
                  </Pane>
                  <Pane onClick={() => handleSubscribe()} className="select-none cursor-pointer font-bpg bg-white rounded-xl text-[#111] flex w-full h-[72px] text-xl font-bold justify-center items-center mt-5">დაჯავშნე ვიზიტი</Pane>
                </Pane>
          }
        </Pane>
        <Pane className="footer">
          <Pane className="font-bpg font-bold text-base">
            <Pane>დამატებითი კითხვების შემთხვევაში,</Pane>
            <Pane>დაგვიკავშირდით: +995 596 555 655</Pane>
          </Pane>
          <Pane className="flex flex-col gap-1">
            <Pane className="font-bpg font-bold text-base">მისამართი</Pane>
            <Pane className="font-fira">ქ. თბილისი, ბელიაშვილის 123</Pane>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

export default Home