import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isConnected: false,
  isReconnecting: false,
  message: "",
  time: null
};

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    serverTime: (state, action) => {
      state.time = action.payload.time;
    },
    socketConnect: (state) => {
      state.isConnected = true;
      state.isReconnecting = false;
    },
    socketDisconnect: (state) => {
      state.isConnected = false;
      state.isReconnecting = true;
    },
    messageReceived: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { socketConnect, socketDisconnect, messageReceived } =
  socketSlice.actions;
export default socketSlice.reducer;
