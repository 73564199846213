import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { Pane } from 'evergreen-ui';

const DatePicker = ({ date, label, onChange }: {date: string, label: string, onChange: (date: any) => void}) => {

	return (
		<Pane className="flex gap-3 flex-col">
			<Pane className="text-xs">{label}</Pane>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
			<MuiDatePicker className='[&>*]:!text-xs' onChange={onChange} defaultValue={dayjs(date)} slotProps={{ textField: { size: 'small' } }} />
			</LocalizationProvider>
		</Pane>
	)

}

export default DatePicker