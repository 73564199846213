import { Collapse, IconButton } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';


const InTableRow = ({ tableCells, item, index, CollapseData, CollapseData2, className, onClick, noData = false, onDblClick }: { onDblClick?: (event: any) => void, noData?: boolean, onClick?: (event: any) => void, tableCells?: any, item?: any, index?: number, CollapseData?: React.ComponentType, CollapseData2?: React.ComponentType, className?: string | undefined | boolean }) => {
	const [open, setOpen] = useState(false);

	if(noData){
		return <TableRow className={`row`}><TableCell colSpan={8} className='hover:!bg-transparent select-none flex justify-center !text-center !p-10 !text-md'>ჩანაწერი ვერ მოიძებნა</TableCell></TableRow>
	}

	return (
		<>
			<TableRow key={index} className={`${className && className} row`} onClick={onClick && onClick} onDoubleClick={onDblClick && onDblClick}>
				{CollapseData && <TableCell >
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
					</IconButton>
				</TableCell>}
				{item.map((cell: any) => generateTableCell(cell))}
				{CollapseData2 && <TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
					</IconButton>
				</TableCell>}
			</TableRow>
			{CollapseData && 
				<TableRow key={Math.random()}>
					<TableCell  key={Math.random()} style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={11}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<CollapseData />
						</Collapse> 
					</TableCell>
				</TableRow>
			}
		</>
	)
}

export function generateTableCell (cell: any){

	if(typeof cell.value === 'object'){
		if(React.isValidElement(cell.value)){
			<TableCell width={cell.width} key={Math.random()} className={cell.className}>{cell.value}</TableCell>
		}else{
			return Object.values(cell.value).map((cell: any) => <TableCell className={cell.className}>{cell.value}</TableCell>)
		}
	}

	// check if function
	if(typeof cell.value === 'function'){
		return <TableCell width={cell.width} key={Math.random()} className={cell.className}>{cell.value()}</TableCell>
	}

	return <TableCell width={cell.width} key={Math.random()}  className={cell.className}>{cell.value}</TableCell>
}

export default InTableRow