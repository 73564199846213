import { Pane, Pill } from 'evergreen-ui'
import React, { useEffect, useState } from 'react'
import { FaHubspot, FaUsers } from 'react-icons/fa6'
import { MdDonutSmall, MdRecentActors } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router'

const ConsoleSidebar = () => {

  const navigate = useNavigate()
  const location = useLocation();

  const [mainMenu, setMainMenu] = useState([
    {
      title: "ჯავშანი",
      icon: <FaHubspot  size={20} />,
      link: "/console",
      count: false,
      active: false
    },
    {
      title: "მომხმარებელი",
      icon: <MdRecentActors size={20} />,
      link: false,
      active: false,
    }
  ])

  const [toolMenu, setToolMenu] = useState([
    {
      title: "რეგისტრაცია",
      icon: <FaUsers   size={20} />,
      link: false,
      active: false,
    },
    {
      title: "სტატისტიკა",
      icon: <MdDonutSmall size={20} />,
      link: false,
      active: false,
    }
  ])

    useEffect(() => {
      setMainMenu(prevMenu =>
        prevMenu.map(menuItem => ({
          ...menuItem,
          active: location.pathname === menuItem.link
        }))
      );
    }, [location.pathname]);

  const renderMenu = ({menu}: any) => {
    return menu.map((item: any) => {
      return (
        <Pane
          className={`menu-item ${item.active ? 'active' : ''}`}
          key={item.title}
          onClick={() => {
            navigate(item.link)
          }}
        >
          {item.icon}
          {item.title}
          {item.count && <Pill className="ml-2">{item.count}</Pill>}
        </Pane>
      )
    })
  }


  return (
    <Pane className="console-sidebar">
    <Pane className="console-sidebar_menu">
      <Pane>
        <Pane className="menu-title">მთავარი მენიუ</Pane>
        <Pane className="menu-list">
          {renderMenu({menu: mainMenu})}
        </Pane>
      </Pane>
      <Pane>
        <Pane className="menu-title border-t pt-2 mt-2"></Pane>
        <Pane className="menu-list">
          {renderMenu({menu: toolMenu})}
        </Pane>
      </Pane>
    </Pane>
    <Pane className="border-t h-14 flex items-center justify-center font-poppins text-xs">
      © {new Date().getFullYear()}. Avtosave.
    </Pane>
  </Pane>
  )
}

export default ConsoleSidebar