import { ErrorIcon, Pane } from 'evergreen-ui'

export const TableErrorMessage = () => {
	return (
		<Pane className="flex justify-center flex-col items-center text-center">
				<Pane className="flex gap-2">
					<ErrorIcon className="fill-neutral-700" />
					მოხდა შეცდომა
				</Pane>
				<Pane>
					გთხოვთ დაუკავშირდეთ პროგრამულ ან IT დეპარტამენტს
				</Pane>
			</Pane>
	)
}