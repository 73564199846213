// write subscribe reducer

import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
    name: "data",
    initialState: {
        brands: {
						data: [],
						loading: false
				},
				models: {
						data: [],
						loading: false
				},
				bookedDates: []
    },
    reducers: {
			bookedDates (state, action) {
				switch(action.payload.type) {
					case "data":
						state.bookedDates = action.payload.value
					break;
					default: 
						state.bookedDates = []
				}
			},
			brands (state, action) {
				switch(action.payload.type) {
					case "loading":
						state.brands.loading = action.payload.value
					break;
					case "data":
						state.brands.data = action.payload.value
						state.brands.loading = false
					break;
					default: 
						state.brands.data = []
						state.brands.loading = false
				}
			},
			models (state, action) {
				switch(action.payload.type) {
					case "loading":
						state.models.loading = action.payload.value
					break;
					case "data":
						state.models.data = action.payload.value
						state.models.loading = false
					break;
					default: 
						state.models.data = []
						state.models.loading = false
				}
			}
    },
});

export const { brands, models } = dataSlice.actions;
export default dataSlice.reducer;