import { Badge, Pane, SearchInput } from 'evergreen-ui'
import React, { useEffect, useState } from 'react'

import TableFilter from '../../components/features/tableFilter/tableFilter.obs';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingRecords } from '../../Redux-store/selectors/booking.action';
import InTableRow from '../../components/features/table/TableRow';
import {TextField } from '@mui/material';
import ObsTable from '../../components/features/table/ObsTable';
import dayjs from 'dayjs';



const ConsoleOrders = () => {

	const [startDate, setStartDate] = useState(dayjs().tz('Asia/Tbilisi').format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState(dayjs().tz('Asia/Tbilisi').format('YYYY-MM-DD'))
	const [currentPage, setCurrentPage] = useState(0)
	const [filterPhone, setFilterPhone] = useState("")
	const [pageSize, setPageSize] = useState(10)
	const bookingSelector = useSelector((state: any) => state.booking)
  const dispatch = useDispatch()

  const [orderCategory, setOrderCategory] = useState([
    {
      id: 0,
      name: "ყველა ჯავშანი",
      active: true
    },
    {
      id: 1,
      name: "მომლოდინე",
      active: false
    },
    {
      id: 2,
      name: "დასრულებული",
      active: false
    },
    {
      id: 3,
      name: "გაუქმებული",
      active: false
    }
  ])

  const handleCategoryChange = (id: number) => {
    setOrderCategory(prev => 
      prev.map((item, i) => ({
        ...item,
        active: item.id === id
      }))
    );

    getBookingRecords({
			startDate,
			endDate,
			phone: filterPhone,
			currentPage,
			pageSize,
      status: id
		})(dispatch)
  };
  

  const OrderCategory = () => {

    return (
      <Pane className="order-category">
        {orderCategory.map((item, index) => {
          return (
            <Pane
              className={`${item.active ? "active" : ""} order-category-item`}
              key={index}
              onClick={() => handleCategoryChange(item.id)}
            >
              {item.name}
            </Pane>
          )
        })}
      </Pane>
    )

  }


  
	useEffect(() => {
		getBookingRecords({
			startDate,
			endDate,
			phone: filterPhone,
			currentPage,
			pageSize,
      status: orderCategory.filter(item => item.active)[0].id
		})(dispatch)
	}, [])

	const RenderTableRows = () => {

    if(bookingSelector?.data?.records?.length === 0) {
      return (
        <InTableRow
          noData={true}
				/>
      )
    }

		return bookingSelector?.data?.records?.map((item: any, index: number) => {

			const modifiedData = [
				{
					value: item.id
				},
				{
					value: <Pane className={`${item.status === 1 ? "bg-orange-400/80 text-orange-850" : item.status === 2 ? "bg-green-400/80 text-green-800" : "bg-red-400/80 text-red-800"} } text-center rounded-full py-2 `}>{item.status_name}</Pane>
				},
				{
					value: item.name
				},
				{
					value: item.phone
				},
				{
					value: item.email
				},
				{
					value: item.car_brand
				},
				{
					value: item.car_model
				},
				{
					value: <Pane>{new Date(item.date).toLocaleDateString('ka-GE', {
						day: 'numeric',
						month: 'short',
						year: 'numeric'
					})}, {item.time}</Pane>
				}
			]

			return (
				<InTableRow
					item={modifiedData}
					index={index}
				/>
			);

		})
	
	};

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number) => {
		setCurrentPage(newPage)
		getBookingRecords({
			startDate,
			endDate,
			phone: filterPhone,
			currentPage: newPage,
			pageSize,
      status: orderCategory.filter(item => item.active)[0].id
		})(dispatch)
	}

	const handleChangeRowsPerPage = (pageSize: any) => {
		setPageSize(pageSize.target.value)
		getBookingRecords({
			startDate,
			endDate,
			phone: filterPhone,
			currentPage: currentPage,
			pageSize: pageSize.target.value,
      status: orderCategory.filter(item => item.active)[0].id
		})(dispatch)
	}

  return (
    <Pane className="w-full flex flex-col justify-center px-8 py-8">

      <Pane className="container-box flex justify-center">
        <OrderCategory />
      </Pane>

      <Pane className="container-box flex justify-between mt-6 px-6 py-4">
      <TableFilter 
        date={{ startDate, setStartDate, endDate, setEndDate }}
        onSubmit={() =>
          getBookingRecords({
            startDate,
            endDate,
            phone: filterPhone,
            currentPage,
            pageSize,
            status: orderCategory.filter(item => item.active)[0].id
          })(dispatch)
        }
        additionalComponent={[
          <TextField
            value={filterPhone}
            onChange={e => setFilterPhone(e.target.value)}
            className="[&>*]:!text-xs"
            placeholder="ძებნა"
            variant="outlined"
            size="small"
          />
        ]}
      />
      </Pane>

      <Pane className="mt-6 rounded-md">
      <ObsTable
							BodyRows={RenderTableRows}
							headerRows={[
								{ name: "#" },
								{ name: "სტატუსი"},
								{ name: "სახელი, გვარი"},
								{ name: "ტელეფონის ნომერი" },
								{ name: "ელ. ფოსტა"},
								{ name: 'მარკა'},
								{ name: "მოდელი"},
								{ name: "დრო" }
							]}
							error={false}
							loading={bookingSelector?.loading}
							option={{ action: false, collapse: false }}
							pagination={{
								show: true,
								total: bookingSelector?.data?.totalCount,
								page: currentPage,
								rowsPerPage: pageSize,
								handleChangePage: handleChangePage,
								handleChangeRowsPerPage: handleChangeRowsPerPage,
							}}
						/>
      </Pane>

      
    </Pane>
  )
}

export default ConsoleOrders