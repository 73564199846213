import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from "react-router-dom";
import Auth from './containers/auth';
import ConsoleHome from './containers/console';
import Home from "./containers/home";
import ContentProvider from './providers/content.provider';
import { checkAuthToken } from './Redux-store/selectors/auth.action';
import { connectToSocket, ListenSocketMessages } from './Redux-store/selectors/socket.action';
import { LocalStorage } from './utils/helpers/localStorage';
import ConsoleCustomers from './containers/console/customers';
import ConsoleOrders from './containers/console/orders';

const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    const token = LocalStorage.get('token');
    token && checkAuthToken()(dispatch);
  }, []);

  useEffect(() => {
    connectToSocket()(dispatch);
    ListenSocketMessages()(dispatch);
  }, []);

  return (
    <Routes>
        <Route path="/" element={<ContentProvider><Home /></ContentProvider>} />
        <Route path="/auth" element={<ContentProvider><Auth /></ContentProvider>} />
        <Route path="/console" element={<ContentProvider><ConsoleHome><ConsoleOrders /></ConsoleHome></ContentProvider>} />
        <Route path="/console/customers" element={<ContentProvider><ConsoleHome><ConsoleCustomers /></ConsoleHome></ContentProvider>} />
    </Routes>
  )
}

export default App