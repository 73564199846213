import { Pane} from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as Logo } from '../../assets/icons/Logo.svg';
import "./index.css";
import {  MdCircleNotifications, MdEmail } from 'react-icons/md';
import ConsoleSidebar from './sidebar';
import { logout } from '../../Redux-store/selectors/auth.action';


const ConsoleHome = ({children}: any) => {

	const authSelector = useSelector((state: any) => state.auth)
	const navigate = useNavigate();
	const dispatch = useDispatch()

	useEffect(() => {
		document.title = "Avtosave / Console";
	}, [])

	useEffect(() => {
		if(authSelector?.isLoggedIn) return
		!authSelector.loading && navigate('/auth')
	}, [])


	const handleAuth = () => {
			logout()(dispatch)
			navigate('/auth')
 }

	return (
		<Pane className="md:h-screen max-md:!min-h-[1000px] overflow-hidden">
      <Pane className="console-header">
        <Pane>
          <Logo className="h-12 w-auto cursor-pointer" onClick={() => navigate("/")} />
        </Pane>
				<Pane className="flex gap-5 items-center">
					<Pane className="flex gap-2 items-center font-bpg cursor-pointer hover:bg-black hover:text-white rounded-lg px-3 py-1">
						<MdCircleNotifications size={35} /> 0
					</Pane>
					<Pane className="flex gap-2 items-center mr-5 font-bpg cursor-pointer hover:bg-black hover:text-white rounded-lg px-3 py-1">
						<MdEmail size={35} /> 0
					</Pane>
					<Pane className="font-bpg text-base text-center rounded-lg px-4 py-3 bg-[#111] text-white cursor-pointer w-min place-items-end" onClick={handleAuth}>გასვლა</Pane>
        </Pane>
      </Pane>
      <Pane className="consoleHome">
       <ConsoleSidebar />
        <Pane className="console-content">
          {children}
        </Pane>
      </Pane>
    </Pane>
	)
}

export default ConsoleHome